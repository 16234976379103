import React from 'react'
import { Navigate } from 'react-router-dom';

function Protected({ children }) {
  if (localStorage.getItem('auth_token')) {
    return children;
  }
  else {
  return <Navigate to="/login" replace />
  }
}
export default Protected;