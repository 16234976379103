import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Breadcrumbs, Typography, TextField, Button, Box, FormControl, MenuItem, Select, InputLabel, FormGroup, InputAdornment, Paper } from "@mui/material";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
function AddFuel() {

    const params = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [vehicles, setVehicles] = useState();
    const [vehicle_id, setVehicleId] = useState("");
    const [quantity, setQuantity] = useState("");
    const [price_per_litre, setPricePerLitre] = useState("");
    const [odo_meter_reading, setODOMeterReading] = useState("");
    const [date, setDateValue] = useState(new Date());
    const [validationErrors, setValidationErrors] = useState({});
    useEffect(() => {
        fetchAllVehicles();
    }, []);

    const fetchAllVehicles = async () => {
        let isMounted = true;

        axios.get(`${process.env.REACT_APP_BASE_URL}/api/vehicles`).then(res => {
            if (isMounted) {
                if (res.status === 200) {
                    setVehicles(res.data);
                    setLoading(false);
                }
            }
        });

        return () => {
            isMounted = false
        };
    }
    const addFuelForm = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('vehicle_id', vehicle_id);
        formData.append('quantity', quantity);
        formData.append('price', price_per_litre);
        formData.append('odometer_reading', odo_meter_reading);
        formData.append('date', date);
        await axios.get(`${process.env.REACT_APP_BASE_URL}/sanctum/csrf-cookie`).then(response => {
            axios.post(`${process.env.REACT_APP_BASE_URL}/api/vehicle/add/fuel`, formData).then((res) => {
                if (res.data.status == '200') {
                    Swal.fire({
                        icon: 'success',
                        text: res.data.message,
                    })
                    navigate("/admin/vehicle-fuel-listing");
                }
                else {
                    setValidationErrors(res.data.validation_errors);
                }

            })
        });
    }
    var viewvehicle_HTMLLIST = "";
    if (loading) {
        return <div className="admin-loader-container"></div>
    }
    else {
        viewvehicle_HTMLLIST =
            vehicles && vehicles.map((item) => {
                return (
                    <MenuItem value={item.id} key={item.id}>{item.name + '  ( ' + item.registration_no + ' )'}</MenuItem>
                )
            });
    }
    return (
        <div className="container-fluid px-4">
            <h1 className="mt-4">Add Fuel</h1>
            <div role="presentation" className="breadcrumb mb-4">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="primary" to="/admin/dashboard">
                        Dashboard
                    </Link>
                    <Typography color="text.primary">Add Fuel</Typography>
                </Breadcrumbs>
            </div>
            <Paper elevation={4}>
                <div className="card mb-4">
                    <div className="card-body">
                        <div className="form-wrapper">
                            <Form onSubmit={addFuelForm}>
                                <Row>
                                    <Col md={3} lg={3}>
                                        <FormGroup className="mb-3">
                                            <FormControl fullWidth size="small">
                                                <InputLabel id="demo-select-small">Select Vehicle</InputLabel>
                                                <Select
                                                    required
                                                    labelId="demo-select-small"
                                                    id="demo-select-small"
                                                    value={vehicle_id}
                                                    label="Select Vehicle"
                                                    onChange={(e) => { setVehicleId(e.target.value) }}>
                                                    {
                                                        viewvehicle_HTMLLIST
                                                    }
                                                </Select>
                                            </FormControl>
                                            <span className="text-red fs-13">
                                                {validationErrors.vehicle_id}
                                            </span>
                                        </FormGroup>
                                    </Col>
                                    <Col md={3} lg={3}>
                                        <FormGroup className="mb-3">
                                            <FormControl fullWidth>
                                                <TextField size="small"
                                                    label="Quantity"
                                                    variant="outlined"
                                                    id="quantity outlined-start-adornment"
                                                    value={quantity}
                                                    onChange={(e) => { setQuantity(e.target.value) }} required
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                    }}
                                                />
                                            </FormControl>
                                            <span className="text-red fs-13">
                                                {validationErrors.quantity}
                                            </span>
                                        </FormGroup>
                                    </Col>
                                    <Col  md={3} lg={3}>
                                        <FormGroup className="mb-3">
                                            <FormControl fullWidth>
                                                <TextField size="small"
                                                    label="Price / Litre"
                                                    variant="outlined"
                                                    id="price outlined-start-adornment"
                                                    value={price_per_litre}
                                                    onChange={(e) => { setPricePerLitre(e.target.value) }} required
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                    }}
                                                />
                                            </FormControl>
                                            <span className="text-red fs-13">
                                                {validationErrors.price}
                                            </span>
                                        </FormGroup>
                                    </Col>
                                    <Col  md={3} lg={3}>
                                        <FormGroup className="mb-3">
                                            <FormControl fullWidth>
                                                <TextField size="small"
                                                    label="ODOMeter Reading"
                                                    variant="outlined"
                                                    id="odometer outlined-start-adornment"
                                                    value={odo_meter_reading}
                                                    onChange={(e) => { setODOMeterReading(e.target.value) }} required
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                    }}
                                                />
                                            </FormControl>
                                            <span className="text-red fs-13">
                                                {validationErrors.odometer_reading}
                                            </span>
                                        </FormGroup>
                                    </Col>
                                    <Col  md={3} lg={3}>
                                    <FormGroup className="mb-3">
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    autoOk={true}
                                                    label="Date"
                                                    value={date}
                                                    onChange={(newDate) => { setDateValue(newDate) }}
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                    </FormGroup>
                                </Col>
                                </Row>
                                <Button size="medium" type="submit" variant="contained" color="primary">Save</Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </Paper>
        </div>
    );
}
export default AddFuel;