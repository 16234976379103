import { Navigate, Outlet } from "react-router-dom";

const RoleSuperAdminAccess = ({ children }) => {

  if (localStorage.getItem('role') == "super-admin") {
    return children;
  }
  else{
    return <div>You don't have rights to access this page</div>;
  }
};
export default RoleSuperAdminAccess;