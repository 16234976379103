import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import { alpha, styled } from '@mui/material/styles';
import { DataGrid, GridToolbarContainer, GridToolbarExport, gridClasses, GridToolbarFilterButton } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns'
import { Breadcrumbs, Paper, Tab, Tabs, Typography, TabPanel, Box } from "@mui/material";

function VehicleFuelListing() {


    const [loading, setLoading] = useState(true);
    const [pendingFuelData, setPendingFuelData] = useState([]);
    const [statusUpdatedFuelData, setStatusUpdatedFuelData] = useState([]);
    const [allFuelData, setAllFuelData] = useState([]);

    const [tabValue, setTabsValue] = useState(0);
    const handleChange = (event, newValue) => {
        setTabsValue(newValue);
    };
    const [search, setSearch] = useState("");

    useEffect(() => {
        fetchVehiclesFuel();
    }, []);

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
                <GridToolbarFilterButton />
            </GridToolbarContainer>
        );
    }

    const ODD_OPACITY = 0.2;

    const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
        [`& .${gridClasses.row}.even`]: {
            backgroundColor: theme.palette.grey[200],
            '&:hover, &.Mui-hovered': {
                backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
            '&.Mui-selected': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    ODD_OPACITY + theme.palette.action.selectedOpacity,
                ),
                '&:hover, &.Mui-hovered': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        ODD_OPACITY +
                        theme.palette.action.selectedOpacity +
                        theme.palette.action.hoverOpacity,
                    ),
                    // Reset on touch devices, it doesn't add specificity
                    '@media (hover: none)': {
                        backgroundColor: alpha(
                            theme.palette.primary.main,
                            ODD_OPACITY + theme.palette.action.selectedOpacity,
                        ),
                    },
                },
            },
        },
    }));

    const fetchVehiclesFuel = async () => {
        let isMounted = true;

        axios.get(`${process.env.REACT_APP_BASE_URL}/api/vehicle-fuel-listing`).then(res => {

            if (isMounted) {
                if (res.status === 200) {
                    setPendingFuelData(res.data.pendingFuelData);
                    setStatusUpdatedFuelData(res.data.statusUpdatedFuelData);
                    setAllFuelData(res.data.fuelData);
                    setLoading(false);
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: 'Something went wrong !!'
                    });
                    setLoading(false);
                }
            }
        });

        return () => {
            isMounted = false
        };
    }

    const rejectFuelEntry = async (id) => {
        const formData = new FormData();
        formData.append('_method', 'POST');
        formData.append('status', 2);
        const isConfirm = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, reject it!'
        }).then((result) => {
            return result.isConfirmed
        });
        if (!isConfirm) {
            return;
        }
        await axios.get(`${process.env.REACT_APP_BASE_URL}/sanctum/csrf-cookie`).then(response => {
            axios.post(`${process.env.REACT_APP_BASE_URL}/api/fuel-status/update/${id}`, formData).then((data) => {
                Swal.fire({
                    icon: "success",
                    text: 'Fuel Entry Rejected Successfully!!'
                })
                fetchVehiclesFuel();
            }).catch(({ response: { data } }) => {
                Swal.fire({
                    text: data.message,
                    icon: "error"
                })
            })
        })
    }

    const approveFuelEntry = async (id) => {

        const formData = new FormData();
        formData.append('_method', 'POST');
        formData.append('status', 1);
        await axios.get(`${process.env.REACT_APP_BASE_URL}/sanctum/csrf-cookie`).then(response => {
            axios.post(`${process.env.REACT_APP_BASE_URL}/api/fuel-status/update/${id}`, formData).then((data) => {
                Swal.fire({
                    icon: "success",
                    text: 'Fuel Entry Approved Successfully!!'
                })
                fetchVehiclesFuel();
            }).catch(({ response: { data } }) => {
                Swal.fire({
                    text: data.message,
                    icon: "error"
                })
            })
        })
    }

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const pendingColumns = [
        {
            field: "name",
            headerName: 'Name',
        },
        {
            field: 'registration_no',
            headerName: 'Registration #',
            flex: 1,
        },
        {
            field: 'quantity',
            headerName: 'quantity',
        },
        {
            field: 'price',
            headerName: 'Price / Litre',
            flex: 1,
        },
        {
            field: 'mileage_per_litre',
            headerName: 'Mileage / Litre',
            flex: 1,
        },
        {
            field: 'odometer_reading',
            headerName: 'Odometer Reading',
            flex: 1,
        },
        {
            field: 'date',
            headerName: 'Date',
            flex: 1,
        },
        {
            field: 'actions', headerName: 'Actions', sortable: false, renderCell: (params) => {
                return (
                    <div>
                        <button type="button" className="btn btn-success btn-sm mr-5" onClick={() => approveFuelEntry(params.id)} title="Approve"><i className="fas fa-check"></i></button>
                        <button type="button" className="btn btn-danger btn-sm reject-btn" onClick={() => rejectFuelEntry(params.id)} title="Reject"><i className="fa fa-close"></i></button>
                    </div>
                );
            }
        }

    ];

    const columns = [
        {
            field: "name",
            headerName: 'Name',
        },
        {
            field: 'registration_no',
            headerName: 'Registration #',
            flex: 1,
        },
        {
            field: 'quantity',
            headerName: 'quantity',
        },
        {
            field: 'price',
            headerName: 'Price / Litre',
        },
        {
            field: 'mileage_per_litre',
            headerName: 'Mileage / Litre',
            flex: 1,
        },
        {
            field: 'odometer_reading',
            headerName: 'Odometer Reading',
            flex: 1,
        },
        {
            field: 'date',
            headerName: 'Date',
            flex: 1,
        },
        {
            field: 'time',
            headerName: 'TIme',
            flex: 1,
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
        },

    ];

    const pendingRowData = pendingFuelData?.map(data => {
        return {
            id: data.id,
            name: data.vehicle.name,
            registration_no: data.vehicle.registration_no,
            quantity: data.quantity,
            price: data.price,
            mileage_per_litre: data.mileage_per_litre,
            odometer_reading: data.odometer_reading,
            date: format(parseISO(data.fuel_entry_date), 'MM/dd/Y'),
            time: format(parseISO(data.fuel_entry_date), 'HH:mm a'),
        };
    })

    const statusUpdatedRowData = statusUpdatedFuelData?.map(data => {
        return {
            id: data.id,
            name: data.vehicle.name,
            registration_no: data.vehicle.registration_no,
            quantity: data.quantity,
            price: data.price,
            mileage_per_litre: data.mileage_per_litre,
            odometer_reading: data.odometer_reading,
            date: format(parseISO(data.fuel_entry_date), 'MM/dd/Y'),
            time: format(parseISO(data.fuel_entry_date), 'HH:mm a'),
            status: (data.status == 1) ? 'Approved' : (data.status == 2) ? 'Rejected' : 'Pending',
        };
    });

    const rowData = allFuelData?.map(data => {
        return {
            id: data.id,
            name: data.vehicle.name,
            registration_no: data.vehicle.registration_no,
            quantity: data.quantity,
            price: data.price,
            mileage_per_litre: data.mileage_per_litre,
            odometer_reading: data.odometer_reading,
            date: format(parseISO(data.fuel_entry_date), 'MM/dd/Y'),
            time: format(parseISO(data.fuel_entry_date), 'HH:mm a'),
            status: (data.status == 1) ? 'Approved' : (data.status == 2) ? 'Rejected' : 'Pending',
        };
    });


    var view_pending_fuel_HTMLTABLE = "";
    var view_all_fuel_HTMLTABLE = "";
    var view_status_updated_fuel_HTMLTABLE = "";


    if (loading) {
        return <div className="admin-loader-container"></div>
    }
    else {

        view_pending_fuel_HTMLTABLE =
            <StripedDataGrid
                components={{
                    Toolbar: CustomToolbar,
                }}

                autoHeight
                rows={pendingRowData}
                columns={pendingColumns}
                pageSize={5}
                rowsPerPageOptions={[5, 10, 20]}
                checkboxSelection
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
            />

        view_status_updated_fuel_HTMLTABLE =
            <StripedDataGrid
                components={{
                    Toolbar: CustomToolbar,
                }}
                autoHeight
                rows={statusUpdatedRowData}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5, 10, 20]}
                checkboxSelection
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
            />

        view_all_fuel_HTMLTABLE =
            <StripedDataGrid
                components={{
                    Toolbar: CustomToolbar,
                }}
                autoHeight
                rows={rowData}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5, 10, 20]}
                checkboxSelection
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
            />
    }

    var view_fuel_tab = "";
    if (localStorage.getItem('role') === 'super-admin') {
        view_fuel_tab =
            <div>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Pending" {...a11yProps(0)} />
                        <Tab label="Approved / Rejected" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <TabPanel value={tabValue} index={0}>
                    <Box sx={{ width: '100%' }}>{view_pending_fuel_HTMLTABLE}</Box>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <Box sx={{ width: '100%' }}>{view_status_updated_fuel_HTMLTABLE}</Box>
                </TabPanel>
            </div>
    }
    if (localStorage.getItem('role') === 'user') {
        view_fuel_tab =
            <div>
                <Box sx={{ width: '100%' }}>{view_all_fuel_HTMLTABLE}</Box>
            </div>
    }

    return (
        <div className="container-fluid vehicle-fuel-listing px-4">
            <div className="mt-4" style={{ display: "flex" }}>
                <h1 style={{ flex: 1 }}>Fuel List</h1>
            </div>
            <div role="presentation" className="breadcrumb mb-4">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="primary" to="/admin/dashboard">
                        Dashboard
                    </Link>
                    <Typography color="text.primary">Fuel List</Typography>
                </Breadcrumbs>
            </div>
            {view_fuel_tab}
        </div>
    );
}
export default VehicleFuelListing;