import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Button, FormControl, FormGroup, InputAdornment, TextField } from "@mui/material";

function Login() {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [validationError, setValidationErrors] = useState({});
    const navigate = useNavigate();
    const submitForm = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('email', email);
        formData.append('password', password);
        await axios.get(`${process.env.REACT_APP_BASE_URL}/sanctum/csrf-cookie`).then(response => {
            axios.post(`${process.env.REACT_APP_BASE_URL}/api/login`, formData).then((res) => {
                if (res.data.status == '200') {
                    localStorage.setItem('auth_token', res.data.token);
                    localStorage.setItem('auth_name', res.data.username);
                    localStorage.setItem('role', res.data.role);
                    Swal.fire({
                        icon: 'success',
                        text: res.data.message,
                    })
                    navigate("/admin/dashboard");
                }
                else if (res.data.status == '401') {
                    Swal.fire({
                        icon: 'error',
                        text: res.data.message,
                    })
                }
                else {
                    setValidationErrors(res.validation_errors);
                }
            })
        });
    }
    return (
        <div>
            <div id="layoutAuthentication">
                <div id="layoutAuthentication_content">
                    <main>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-5">
                                    <div className="card shadow-lg border-0 rounded-lg mt-5">
                                        <div className="card-header"><h3 className="text-center font-weight-light my-4">Login</h3></div>
                                        <div className="card-body">
                                            <Form onSubmit={submitForm}>
                                                <FormGroup className="mb-3">
                                                    <FormControl fullWidth>
                                                        <TextField size="medium"
                                                            label="Email address"
                                                            variant="outlined"
                                                            id="email outlined-start-adornment"
                                                            type="email"
                                                            value={email || ''}
                                                            onChange={(e) => { setEmail(e.target.value) }} required
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                            }}
                                                        />
                                                    </FormControl>
                                                </FormGroup>
                                                <FormGroup className="mb-3">
                                                    <FormControl fullWidth>
                                                        <TextField size="medium"
                                                            label="Password"
                                                            variant="outlined"
                                                            id="password outlined-start-adornment"
                                                            type="password"
                                                            value={password || ''}
                                                            onChange={(e) => { setPassword(e.target.value) }} required
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                            }}
                                                        />
                                                    </FormControl>
                                                </FormGroup>
                                                <div className="form-check mb-3">
                                                    <input className="form-check-input" id="inputRememberPassword" type="checkbox" value="" />
                                                    <label className="form-check-label">Remember Password</label>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between mt-4 mb-0 pb-3">
                                                    <Button size="medium" type="submit" variant="contained" color="primary">Login</Button>
                                                </div>
                                            </Form>
                                        </div>
                                        <div className="card-footer text-center py-3"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    )
}
export default Login