import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { Button } from "react-bootstrap";

function Navbar () {
    const navigate = useNavigate();
    const logoutSubmit = async (e) => {
        e.preventDefault();
        await axios.get(`${process.env.REACT_APP_BASE_URL}/sanctum/csrf-cookie`).then(response => {
            axios.post(`${process.env.REACT_APP_BASE_URL}/api/logout`).then((res)=>{
                if (res.data.status == '200') {
                    localStorage.removeItem('auth_token');
                    localStorage.removeItem('auth_name');
                    localStorage.removeItem('role');
                    Swal.fire({
                        icon: 'success',
                        text: res.data.message,
                    })
                    navigate("/");
                }
            })
        });
    } 
    var AuthButtons = "";
    if (!localStorage.getItem('auth_token')) {
        AuthButtons = (
            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                <li>
                    <Link className="dropdown-item" to="/login">Login</Link>
                </li>
                <li>
                    <Link className="dropdown-item" to="/register">Register</Link>
                </li>
            </ul>
        );
    }
    else {
        AuthButtons = (
            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                <li>
                    <Button type="button" onClick={logoutSubmit} className="dropdown-item">Logout</Button>
                </li>
            </ul>
        );
    }
    return(
        <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
            {/* <!-- Navbar Brand--> */}
            <Link className="navbar-brand ps-3" to="/admin">FMA</Link>
            {/* <!-- Sidebar Toggle--> */}
            <button className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle" href="#!"><i className="fas fa-bars"></i></button>
            {/* <!-- Navbar Search--> */}
            <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
                <div className="input-group">
                    <input className="form-control" type="text" placeholder="Search for..." aria-label="Search for..." aria-describedby="btnNavbarSearch" />
                    <button className="btn btn-dark border-white" id="btnNavbarSearch" type="button"><i className="fas fa-search"></i></button>
                </div>
            </form>
            {/* <!-- Navbar--> */}
            <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
                <li className="nav-item dropdown">
                    <Link to="#" className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-user fa-fw"></i></Link>
                    {AuthButtons}
                </li>
            </ul>
        </nav>
    );
}
export default Navbar;