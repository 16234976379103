import React from 'react';
import { Link } from "react-router-dom";

function Footer () {
    return(
        <footer className="py-4 bg-light mt-auto">
            <div className="container-fluid px-4">
                <div className="d-flex align-items-center justify-content-between small">
                    <div className="text-muted text-center">Copyright &copy; 2023 - FMA</div>
                </div>
            </div>
        </footer>
    );
}
export default Footer;