import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import axios from "axios";
import './assets/frontend/css/custom.css';
import './assets/frontend/css/styles.css';

import Login from './components/auth/Login';
import MasterLayout from './layouts/MasterLayout';
import Protected from './Protected';
import Dashboard from './components/dashboard/Dashboard';
import Profile from './components/profile/Profile';
import CreateVehicle from './components/vehicles/CreateVehicle';
import VehicleListing from './components/vehicles/VehicleListing';
import AddFuel from './components/fuel/AddFuel';
import FuelHistory from './components/fuel/FuelHistory';
import EditVehicle from './components/vehicles/EditVehicle';
import RoleAdminAccess from './RoleAdminAccess';
import VehicleFuelListing from './components/fuel/VehicleFuelListing';
import UserList from './components/users/UserList';
import NotFound from './components/NotFound';
import RoleSuperAdminAccess from './RoleSuperAdminAccess';

// axios.defaults.baseURL = "http://nxbfuel.vteamslabs.com";
axios.defaults.headers.post["Content-Type"] = 'application/json';
axios.defaults.headers.post["Accept"] = 'application/json';
axios.defaults.withCredentials = true;
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('auth_token');
  config.headers.Authorization = token ? `Bearer ${token}` : ``;
  return config;
})

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('auth_token'));

  const handleLogout = () => {
    localStorage.removeItem('auth_token');
    setIsAuthenticated(false);
  };
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={isAuthenticated ? <Navigate to="/admin/dashboard" replace /> : <Login setIsAuthenticated={setIsAuthenticated} />} />
          <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
          <Route path="/admin" element={<Protected isAuthenticated={isAuthenticated} onLogout={handleLogout}><MasterLayout /></Protected>}>
            <Route exact path='/admin/dashboard' element={<Dashboard />} />
            <Route exact path='/admin/profile' element={<Profile />} />
            <Route exact path='/admin/vehicles' element={<VehicleListing />} />
            <Route exact path='/admin/users' element={<RoleSuperAdminAccess><UserList /></RoleSuperAdminAccess>} />
            <Route exact path='/admin/add/vehicle' element={<RoleAdminAccess><CreateVehicle /></RoleAdminAccess>} />
            <Route exact path='/admin/add/fuel/' element={<AddFuel />} />
            <Route exact path='/admin/vehicle/fuel-history/:id' element={<FuelHistory />} />
            <Route exact path='/admin/vehicle/edit/:id' element={<EditVehicle />} />
            <Route exact path='/admin/vehicle-fuel-listing' element={<VehicleFuelListing />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
