import { Button } from '@mui/material';
import React from 'react'
import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <div className="error-not-found-container">
	   <div className="error_block">
	 	   	<h1>404</h1>
		   	<h3>Oops... Page Not Found!</h3>
		   	<p>Try using the button below to go to main page of the site</p>
		   	<div>
				<Link to="/" style={{ textDecoration: 'none' }}>
                    <Button class="error_btn" size="medium" variant="contained"  color="primary">Home Page</Button>
				</Link>
            </div>
       </div>
	</div>
);
}
export default NotFound;