import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import Swal from 'sweetalert2';
import { Box, Breadcrumbs, Typography } from "@mui/material";
import { DataGrid, GridToolbarContainer, GridToolbarExport, gridClasses, GridToolbarFilterButton } from '@mui/x-data-grid';
import { alpha, styled } from '@mui/material/styles';

function UserList () {

    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
                <GridToolbarFilterButton />
            </GridToolbarContainer>
        );
    }

    const ODD_OPACITY = 0.2;

    const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
        [`& .${gridClasses.row}.even`]: {
            backgroundColor: theme.palette.grey[200],
            '&:hover, &.Mui-hovered': {
                backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
            '&.Mui-selected': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    ODD_OPACITY + theme.palette.action.selectedOpacity,
                ),
                '&:hover, &.Mui-hovered': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        ODD_OPACITY +
                        theme.palette.action.selectedOpacity +
                        theme.palette.action.hoverOpacity,
                    ),
                    // Reset on touch devices, it doesn't add specificity
                    '@media (hover: none)': {
                        backgroundColor: alpha(
                            theme.palette.primary.main,
                            ODD_OPACITY + theme.palette.action.selectedOpacity,
                        ),
                    },
                },
            },
        },
    }));

    useEffect(() => {
        fetchAllUsers();
    }, []);

    const fetchAllUsers = async () => {
        let isMounted = true;

        axios.get(`${process.env.REACT_APP_BASE_URL}/api/users`).then(res => {

            if (isMounted) {
                if (res.status === 200) {
                    setUsers(res.data.users);
                    setLoading(false);
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: 'Something went wrong !!'
                    });
                    setLoading(false);
                }
            }
        });

        return () => {
            isMounted = false
        };
    }

    const columns = [
        {
            field: "name",
            headerName: 'Name',
            flex: 1,
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 1,
        },
        {
            field: 'role',
            headerName: 'Role',
            flex: 1,
        },

    ];
    const rowData = users?.map(data => {
        return {
            id: data.id,
            name: data.name,
            email: data.email,
            role: data.role.name,
        };
    });

    var view_Users_HTMLTABLE = "";

    if (loading) {
        return <div className="admin-loader-container"></div>
    }
    else {
        view_Users_HTMLTABLE =
        <StripedDataGrid
            components={{
                Toolbar: CustomToolbar,
            }}

            autoHeight
            rows={rowData}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
            checkboxSelection
            getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
        />
    }
    return (
        <div className="container-fluid px-4">
            <div className="mt-4" style={{ display: "flex" }}>
                <h1 style={{ flex: 1 }}>User List</h1>
            </div>
            <div role="presentation" className="breadcrumb mb-4">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="primary" to="/admin/dashboard">
                        Dashboard
                    </Link>
                    <Typography color="text.primary">User List</Typography>
                </Breadcrumbs>
            </div>
            <Box sx={{ width: '100%' }}>{view_Users_HTMLTABLE}</Box>
        </div>
    );
}
export default UserList;