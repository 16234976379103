import { Navigate, Outlet } from "react-router-dom";

const RoleAdminAccess = ({ children }) => {

  if (localStorage.getItem('role') == "user") {
    return children;
  }
  else{
    return <div>You don't have rights to access this page</div>;
  }
};
export default RoleAdminAccess;