import React from 'react';
import { Link } from "react-router-dom";

function Sidebar() {

    var navLinks = "";
    if (localStorage.getItem('role') === 'user') {
        navLinks = (
            <div className="nav admin-custom-nav">
                <div className="sb-sidenav-menu-heading">Core</div>
                <Link className="nav-link" to="/admin/dashboard">
                    <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                    Dashboard
                </Link>
                <div className="sb-sidenav-menu-heading">Vehicles</div>
                <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#collapseLayouts" aria-expanded="false" aria-controls="collapseLayouts">
                    <div className="sb-nav-link-icon"><i className="fa-sharp fa-solid fa-truck"></i></div>
                    Vehicles
                    <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                </Link>
                <div className="collapse" id="collapseLayouts" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                    <nav className="sb-sidenav-menu-nested nav">
                        <Link className="nav-link" to="/admin/add/vehicle">Add Vehicle</Link>
                        <Link className="nav-link" to="/admin/vehicles">Vehicle List</Link>
                    </nav>
                </div>
                <div className="sb-sidenav-menu-heading">Fuel</div>
                <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#collapsePages" aria-expanded="false" aria-controls="collapsePages">
                    <div className="sb-nav-link-icon"><i className="fa-solid fa-gas-pump"></i></div>
                    Fuel
                    <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                </Link>
                <div className="collapse" id="collapsePages" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">
                    <nav className="sb-sidenav-menu-nested nav">
                        <Link className="nav-link" to="/admin/add/fuel">Add Fuel</Link>
                        <Link className="nav-link" to="/admin/vehicle-fuel-listing">Fuel List</Link>
                    </nav>
                </div>
            </div>
        );
    }
    if (localStorage.getItem('role') == 'super-admin') {
        navLinks = (
            <div className="nav">
                <div className="sb-sidenav-menu-heading">Core</div>
                <Link className="nav-link" to="/admin/dashboard">
                    <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                    Dashboard
                </Link>
                <div className="sb-sidenav-menu-heading">User Management</div>
                <Link className="nav-link" to="/admin/users">
                    <div className="sb-nav-link-icon"><i className="fa-solid fa-user fs-15"></i></div>
                    Users
                </Link>
                <div className="sb-sidenav-menu-heading">Vehicles</div>
                <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#collapseLayouts" aria-expanded="false" aria-controls="collapseLayouts">
                    <div className="sb-nav-link-icon"><i className="fa-sharp fa-solid fa-truck"></i></div>
                    Vehicles
                    <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                </Link>
                <div className="collapse" id="collapseLayouts" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                    <nav className="sb-sidenav-menu-nested nav">
                        <Link className="nav-link" to="/admin/vehicles">Vehicle List</Link>
                    </nav>
                </div>
                <div className="sb-sidenav-menu-heading">Fuel</div>
                <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#collapsePages" aria-expanded="false" aria-controls="collapsePages">
                    <div className="sb-nav-link-icon"><i className="fa-solid fa-gas-pump"></i></div>
                    Fuel
                    <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                </Link>
                <div className="collapse" id="collapsePages" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">
                    <nav className="sb-sidenav-menu-nested nav">
                        <Link className="nav-link" to="/admin/vehicle-fuel-listing">Fuel List</Link>
                    </nav>
                </div>
            </div>
        );
    }
    return (
        <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
            <div className="sb-sidenav-menu">
                {navLinks}
            </div>
            <div className="sb-sidenav-footer">
                <div className="small">Logged in as:</div>
                FMA
            </div>
        </nav>
    );
}
export default Sidebar;