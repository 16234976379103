import axios from "axios";
import Swal from 'sweetalert2';
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { format, parseISO } from 'date-fns'
import Button from '@mui/material/Button';
import { DataGrid, GridToolbarContainer, GridToolbarExport, gridClasses, GridToolbarFilterButton } from '@mui/x-data-grid';
import { alpha, styled } from '@mui/material/styles';
import { Box, Breadcrumbs, Paper, Typography } from "@mui/material";

function VehicleListing() {

    const [loading, setLoading] = useState(true);
    const [vehicles, setVehicles] = useState([]);
    const [search, setSearch] = useState("");
    const [filteredVehicles, setFilteredVehicles] = useState([]);
    useEffect(() => {
        fetchAllVehicles();
    }, []);

    useEffect(() => {
        const result = vehicles.filter(vehicle => {
            return vehicle.name.toLowerCase().match(search.toLowerCase());
        });
        setFilteredVehicles(result);
    }, [search])

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
                <GridToolbarFilterButton />
            </GridToolbarContainer>
        );
    }

    const ODD_OPACITY = 0.2;

    const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
        [`& .${gridClasses.row}.even`]: {
            backgroundColor: theme.palette.grey[200],
            '&:hover, &.Mui-hovered': {
                backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
            '&.Mui-selected': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    ODD_OPACITY + theme.palette.action.selectedOpacity,
                ),
                '&:hover, &.Mui-hovered': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        ODD_OPACITY +
                        theme.palette.action.selectedOpacity +
                        theme.palette.action.hoverOpacity,
                    ),
                    // Reset on touch devices, it doesn't add specificity
                    '@media (hover: none)': {
                        backgroundColor: alpha(
                            theme.palette.primary.main,
                            ODD_OPACITY + theme.palette.action.selectedOpacity,
                        ),
                    },
                },
            },
        },
    }));

    const fetchAllVehicles = async () => {
        let isMounted = true;

        axios.get(`${process.env.REACT_APP_BASE_URL}/api/vehicles`).then(res => {
            if (isMounted) {
                if (res.status === 200) {
                    setVehicles(res.data);
                    setFilteredVehicles(res.data);
                    setLoading(false);
                }
            }
        });

        return () => {
            isMounted = false
        };
    }
    const noRightsALert = (e) => {
        Swal.fire({
            icon: "error",
            text: 'You do not have sufficient rights to perform this operation!!'
        })
    }

    const deleteVehicle = async (id) => {

        const isConfirm = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            return result.isConfirmed
        });
        if (!isConfirm) {
            return;
        }
        await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/vehicles/delete/${id}`).then((data) => {
            Swal.fire({
                icon: "success",
                text: 'Vehicle Deleted Successfully!!'
            })
            fetchAllVehicles();
        }).catch(({ response: { data } }) => {
            Swal.fire({
                text: data.message,
                icon: "error"
            })
        })
    }
    const columns = [
        {
            field: 'vehicle_image', headerName: 'Image', sortable: false, renderCell: (params) => {
                return (
                    <div> <img width="50px" src={`${process.env.REACT_APP_IMAGES_URL}${params.row.vehicle_image}`} />
                    </div>
                );
            },
            flex: 1,
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
        },
        {
            field: 'model',
            headerName: 'Model',
            flex: 1,
        },
        {
            field: 'registration_no',
            headerName: 'Registration No.',
            flex: 1,
        },
        {
            field: 'fuel_history', headerName: 'Fuel History', sortable: false, renderCell: (params) => {
                return (
                    <div><Link to={`/admin/vehicle/fuel-history/${params.id}`} style={{ textDecoration: 'none' }}>
                        <Button variant="text">Fuel History</Button></Link>
                    </div>
                );
            },
            flex: 1,
        },
        {
            field: 'actions', headerName: 'Actions', sortable: false, renderCell: (params) => {
                if (localStorage.getItem('role') == 'user') {
                    return (
                        <div>
                            <Link to={`/admin/vehicle/edit/${params.id}`} className="btn btn-info btn-sm mr-10 text-white"><i className="fas fa-edit"></i></Link>
                            <button type="button" onClick={() => deleteVehicle(params.id)} className="btn btn-danger btn-sm"><i className="fa fa-trash"></i></button>
                        </div>
                    );
                }
                if (localStorage.getItem('role') == 'super-admin') {
                    return (
                        <div>
                            <Link to={'#'} className="btn btn-info btn-sm mr-10 text-white" onClick={(e) => noRightsALert(e)} ><i className="fas fa-edit"></i></Link>
                            <button type="button" className="btn btn-danger btn-sm" onClick={(e) => noRightsALert(e)} ><i className="fa fa-trash"></i></button>
                        </div>
                    );
                }
            },
            flex: 1,
        }

    ];

    const rowData = filteredVehicles?.map(data => {
        return {
            id: data.id,
            vehicle_image: data.vehicle_image,
            name: data.name,
            model: data.model,
            registration_no: data.registration_no,
        };
    });

    var viewvehichle_HTMLTABLE = "";
    if (loading) {
        return <div className="admin-loader-container"></div>
    }
    else {
        viewvehichle_HTMLTABLE =

            <StripedDataGrid
                components={{
                    Toolbar: CustomToolbar,
                }}
                autoHeight
                rows={rowData}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5, 10, 20]}
                checkboxSelection
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
            />
    }
    return (
        <div className="container-fluid  px-4">
            <div className="mt-4" style={{ display: "flex" }}>
                <h1 style={{ flex: 1 }}>Vehicle List</h1>
                {
                    (localStorage.getItem('role') == 'user') ?
                        <Link to={`/admin/add/vehicle`} style={{ textDecoration: 'none' }}>
                            <Button className="float-end" size="medium" variant="outlined" color="primary">Add Vehicle</Button>
                        </Link>
                        : (localStorage.getItem('role') == 'super-admin') ?
                            <Link to={'#'} style={{ textDecoration: 'none' }}>
                                <Button className="float-end" size="medium" onClick={(e) => noRightsALert(e)} variant="outlined" color="primary">Add Vehicle</Button>
                            </Link>
                            :
                            ''
                }
            </div>
            <div role="presentation" className="breadcrumb mb-4">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="primary" to="/admin/dashboard">
                        Dashboard
                    </Link>
                    <Typography color="text.primary">Vehicle List</Typography>
                </Breadcrumbs>
            </div>
            <Box sx={{ width: '100%' }}>{viewvehichle_HTMLTABLE}</Box>
        </div>
    )
}
export default VehicleListing;