import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams, Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Breadcrumbs, Typography, Button, Paper, TextField, InputAdornment, FormControl, FormGroup } from "@mui/material";

function EditVehicle() {

    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [model, setModel] = useState("");
    const [registration_no, setRegistrationNo] = useState("");
    const [vehicle_image, setImage] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});
    const [loading, setLoading] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        fetchVehicle();
    }, []);
    const fetchVehicle = async () => {
        let isMounted = true;
        try {
            await axios.get(`${process.env.REACT_APP_BASE_URL}/api/vehicles/${id}`).then((res) => {
                if (isMounted) {
                    if (res.status === 200) {
                        setName(res.data.vehicle.name)
                        setModel(res.data.vehicle.model)
                        setRegistrationNo(res.data.vehicle.registration_no)
                        setLoading(false);
                    }
                }
            });
        } catch (error) {
            setLoading(false); // Stop loading in case of error
            console.error(error);
        }
        return () => {
            isMounted = false
        };
    }
    const changeImage = (e) => {
        setImage(e.target.files[0]);
    }
    const updateVehicle = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('_method', 'POST');
        formData.append('name', name);
        formData.append('model', model);
        formData.append('registration_no', registration_no);
        if (vehicle_image !== null) {
            formData.append('vehicle_image', vehicle_image);
        }
        await axios.post(`${process.env.REACT_APP_BASE_URL}/api/vehicles/update/${id}`, formData, {
            headers: {
                'Accept': 'application/json, application/xml, text/plain, text/html, *.*',
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            if (res.data.status == '200') {
                Swal.fire({
                    icon: 'success',
                    text: res.data.message,
                })

                navigate("/admin/vehicles");
            }
            else {
                setValidationErrors(res.data.validation_errors);
            }

        })
    }
    var viewvehichle_HTMLTABLE = "";
    if (loading) {
        return <div className="admin-loader-container"></div>
    }
    else {
        viewvehichle_HTMLTABLE =
            <Form onSubmit={updateVehicle}>
                <Row>
                    <Col>
                        <FormGroup className="mb-3">
                            <FormControl fullWidth>
                                <TextField size="small"
                                    label="Name"
                                    variant="outlined"
                                    id="name outlined-start-adornment"
                                    value={name || ""}
                                    onChange={(e) => { setName(e.target.value) }} required
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    }}
                                />
                            </FormControl>
                            <span className="text-red fs-13">
                                {validationErrors.name}
                            </span>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup className="mb-3">
                            <FormControl fullWidth>
                                <TextField size="small"
                                    label="Model"
                                    variant="outlined"
                                    id="model outlined-start-adornment"
                                    value={model || ""}
                                    onChange={(e) => { setModel(e.target.value) }} required
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    }}
                                />
                            </FormControl>
                            <span className="text-red fs-13">
                                {validationErrors.model}
                            </span>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup className="mb-3">
                            <FormControl fullWidth>
                                <TextField size="small"
                                    label="Registration No."
                                    variant="outlined"
                                    id="registration_no outlined-start-adornment"
                                    value={registration_no || ""}
                                    onChange={(e) => { setRegistrationNo(e.target.value) }} required
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    }}
                                />
                            </FormControl>
                            <span className="text-red fs-13">
                                {validationErrors.registration_no}
                            </span>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup className="mb-3">
                            <Form.Label>Image</Form.Label>
                            <Form.Control type="file"
                                onChange={changeImage}
                            />
                            <span className="text-red fs-13">
                                {validationErrors.vehicle_image}
                            </span>
                        </FormGroup>
                    </Col>
                </Row>
                <Button size="medium" type="submit" variant="contained" color="primary">Save</Button>
            </Form>
    }
    return (
        <div className="container-fluid px-4">
            <div className="mt-4" style={{ display: "flex" }}>
                <h1 style={{ flex: 1 }}>Edit Vehicle</h1>
                <Link to={`/admin/vehicles`} style={{ textDecoration: 'none' }}>
                    <Button className="float-end" size="medium" variant="outlined" color="primary">View Vehicles</Button>
                </Link>
            </div>
            <div role="presentation" className="breadcrumb mb-4">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="primary" to="/admin/dashboard">
                        Dashboard
                    </Link>
                    <Typography color="text.primary">Add Vehicle</Typography>
                </Breadcrumbs>
            </div>
            <Paper elevation={4}>
                <div className="card mb-4">
                    <div className="card-body">
                        <div className="form-wrapper">
                            {viewvehichle_HTMLTABLE}
                        </div>
                    </div>
                </div>
            </Paper>
        </div>
    )
}
export default EditVehicle;