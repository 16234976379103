import axios from "axios";
import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Swal from "sweetalert2";
import { format, parseISO } from "date-fns"
import { DataGrid, GridToolbarContainer, GridToolbarExport, gridClasses, GridToolbarFilterButton } from '@mui/x-data-grid';
import { alpha, styled } from '@mui/material/styles';
import { Breadcrumbs, Paper, Typography } from "@mui/material";

function FuelHistory() {

    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [vehicle_fuel_history, setVehicleFuelHistory] = useState();
    const [vehicleFuelMileage, setVehicleFuelMileage] = useState("");

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
                <GridToolbarFilterButton />
            </GridToolbarContainer>
        );
    }

    const ODD_OPACITY = 0.2;

    const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
        [`& .${gridClasses.row}.even`]: {
            backgroundColor: theme.palette.grey[200],
            '&:hover, &.Mui-hovered': {
                backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
            '&.Mui-selected': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    ODD_OPACITY + theme.palette.action.selectedOpacity,
                ),
                '&:hover, &.Mui-hovered': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        ODD_OPACITY +
                        theme.palette.action.selectedOpacity +
                        theme.palette.action.hoverOpacity,
                    ),
                    // Reset on touch devices, it doesn't add specificity
                    '@media (hover: none)': {
                        backgroundColor: alpha(
                            theme.palette.primary.main,
                            ODD_OPACITY + theme.palette.action.selectedOpacity,
                        ),
                    },
                },
            },
        },
    }));

    useEffect(() => {
        fetchVehicleFuelHistory();
    }, []);

    const fetchVehicleFuelHistory = async () => {
        let isMounted = true;

        axios.get(`${process.env.REACT_APP_BASE_URL}/api/vehicle/fuel-history/${params.id}`).then(res => {
            if (isMounted) {
                if (res.data.status == 200) {
                    setVehicleFuelHistory(res.data.vehicle_fuel_history);
                    setVehicleFuelMileage(res.data.mileage_per_litre);
                    setLoading(false);
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: res.data.message,
                    })
                }
            }
        });

        return () => {
            isMounted = false
        };
    }

    const columns = [
        {
            field: 'quantity',
            headerName: 'Quantity',
            flex: 1,
        },
        {
            field: 'price',
            headerName: 'Price / Litre',
            flex: 1,
        },
        {
            field: 'mileage_per_litre',
            headerName: 'Mileage / Litre',
            flex: 1,
        },
        {
            field: 'odometer_reading',
            headerName: 'Odometer Reading',
            flex: 1,
        },
        {
            field: 'date',
            headerName: 'Date',
            flex: 1,
        },
        {
            field: 'time',
            headerName: 'TIme',
            flex: 1,
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
        },

    ];

    let rowData = [];
    vehicle_fuel_history?.forEach(vehicle => {
        vehicle.fuel.forEach((fuel, index) => {
            rowData.push({
                id: index,
                quantity: fuel.quantity,
                price: fuel.price,
                odometer_reading: fuel.odometer_reading,
                mileage_per_litre: fuel.mileage_per_litre,
                date: format(parseISO(fuel.fuel_entry_date), 'MM/dd/Y'),
                time: format(parseISO(fuel.fuel_entry_date), 'HH:mm a'),
                status: (fuel.status == 1) ? 'Approved' : (fuel.status == 2) ? 'Rejected' : 'Pending',
            });
        });
    });

    var vehicle_fuel_history_LIST = "";
    var vehicle_Detail = "";
    var vehicle_fuel_mileage = "";
    if (loading) {
        return <div className="admin-loader-container"></div>
    }
    else {
        vehicle_fuel_mileage =
            <span>{vehicleFuelMileage.mileage_per_litre}</span>
        vehicle_Detail =
            vehicle_fuel_history && vehicle_fuel_history.map((item) => {
                return (
                    <div className="row" key={item.id}>
                        <div className="col-md-4">
                            <img className="w-100 vdetail_image" src={`${process.env.REACT_APP_IMAGES_URL}${item.vehicle_image}`} />
                        </div>
                        <div className="col-md-8 px-4">
                            <h2><span>{item.name ?? '-'}</span></h2>

                            <div className="mt-4 admin_vehicle_details">
                                <ul className="vdetail_list">
                                    <li className="vdetail_item">
                                        <label className="vdetail_item_label">Model: </label>
                                        {item.model ?? '-'}
                                    </li>
                                    <li className="vdetail_item">
                                        <label className="vdetail_item_label">Registration No: </label>
                                        {item.registration_no ?? '-'}
                                    </li>
                                    <li className="vdetail_item">
                                        <label className="vdetail_item_label">Mileage/Litre: </label>
                                        {vehicle_fuel_mileage ?? '-'}
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                )
            })
        vehicle_fuel_history_LIST =
            <StripedDataGrid
                components={{
                    Toolbar: CustomToolbar,
                }}
                autoHeight
                rows={rowData}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5, 10, 20]}
                checkboxSelection
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
            />
    }
    return (
        <div className="container-fluid px-4">
            <div className="mt-4">
                <h1>Vehicle Fuel History</h1>
            </div>
            <div role="presentation" className="breadcrumb mb-4">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="primary" to="/admin/dashboard">
                        Dashboard
                    </Link>
                    <Typography color="text.primary">Vehicle Fuel History</Typography>
                </Breadcrumbs>
            </div>
            <Paper elevation={4}>
                <div className="card mb-4">
                    <div className="card-body">
                        {vehicle_Detail}
                        <hr />
                        <h4>Fuel History</h4>
                        {vehicle_fuel_history_LIST}
                    </div>
                </div>
            </Paper>
        </div>
    )
}
export default FuelHistory;